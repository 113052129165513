


























































































import {Component, Emit, Mixins, Prop} from "vue-property-decorator";
import {mapGetters, mapMutations} from "vuex";
import Page from "@/components/for-page-grid/Page.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import XForm from "@/components/SimpleForm.vue";
import XNotice from "@/components/hoc/SimpleNotice.vue";
import {validationMixin} from "vuelidate";
import {appealChatForm} from "@/assets/scripts/form-fields/main";
import {AppealChatMessages, AppealChatFiles} from "@/models/appeals";
import {OrganizationAgent} from "@/models/organization";
import {appealsAcceptedExtensions, appealsAcceptedExtensionsHint} from "@/assets/scripts/file-inputs";
import XAppealChatMessage from "@/components/for-appeals/AppealChatMessage.vue";

const { values, attrs, validations, errors } = appealChatForm;

@Component({
  components: {
    Page,
    XForm,
    XNotice,
    XAppealChatMessage
  },
  computed: {
    ...errors,
    ...mapGetters({
      contractId: "contract/id",
      currentAppealInfo: "appeals/getCurrentAppealInfo",
      orgContactAgent: "organization/agentList",
      unreadAppealsCount: "appeals/unreadAppealsCount",
      orgIdOfContractId: "contract/orgIdOfContractId",
    }),
  },
  methods: {
    ...mapMutations("appeals", {
      setUnreadCounterValue: "setUnreadCounterValue",
    }),
    ...mapMutations("sidebar", {
      setCounterValue: "setCounterValue",
    }),
  },
  mixins: [validationMixin],
  validations,
})

class AppealChat extends Mixins(AppApiMixin, XForm) {
  [x: string]: any;

  @Prop({ default: false }) readonly show!: boolean;

  values = values;
  defaultValues: {[x: string]: any} = {}
  allFieldAttrs = attrs;

  useSubmitButtonLoading = false;
  useSuccessAlert = false;
  submitSuccess = "";
  submitErrorText = "";

  appealsChatList: AppealChatMessages[] = [];

  sendAuthorStr = "";


  /**
   * Доступные расширения для файлов
   */
  getAppealsAcceptedExtensions() { return appealsAcceptedExtensions() }
  getAppealsAcceptedExtensionsHint() { return appealsAcceptedExtensionsHint() }

  /**
   * получить список сообщений и список файлов в сообщениях
   */
  getChatList() {
    this.getAppealFiles({
      contractId: this.contractId,
      appealId: parseInt(this.currentAppealInfo[0]['appealId'])
    }, (appealsChatListFiles: AppealChatFiles[]) => {
      this.getAppealHistory({
        contractId: this.contractId,
        appealId: parseInt(this.currentAppealInfo[0]['appealId'])
      },(appealsChatList: AppealChatMessages[]) => {
        const arr = appealsChatListFiles.map((appealsChatFile) => {
          return {
            'file': appealsChatFile['наименование'],
            'fileId': appealsChatFile['$номерЗаписи'],
          };
        });
        appealsChatList = appealsChatList.map((appealsChat) => {
          Object.assign(appealsChat, arr.find((appealsChatFile) =>
            appealsChat['содержание'].includes(appealsChatFile['file'])
          ))
          return appealsChat
        });
        this.appealsChatList = appealsChatList;
      });
    });
  }

  /**
   * Обновить список непрочитанных
   */
  getUnreadCount() {
    let end_check = Array.from(this.unreadAppealsCount).find(
      item => item['$номерЗаписи'] == this.currentAppealInfo[0]['appealId']
    );
    if (end_check) {
      this.resetUnreadAppeal(
        this.currentAppealInfo[0]['appealId'],
        (data) => {
          if (data && data.data.result) {
            let fin_mas = Array.from(
              this.unreadAppealsCount).filter(item => item['$номерЗаписи'] != this.currentAppealInfo[0]['appealId']
            );
            if (fin_mas.length !=0) {
              this.setUnreadCounterValue(fin_mas);
              this.setCounterValue(fin_mas.length);
            } else {
              this.setUnreadCounterValue([]);
              this.setCounterValue(0);
            }
            this.$emit('updateCounter');
          }
        }
      );
    }
  }

  /**
   * Получения данных о заявителе
   */
  getAuthor() {
    if (this.orgContactAgent == undefined) {
      // когда нет контактного лица
      return;
    }
    if (Array.from(this.orgContactAgent).length > 0) {
      Array.from(this.orgContactAgent).map((item: OrganizationAgent) => {
        this.sendAuthorStr += item.фио + ': ' + item.должность + ' ';
      });
    } else {
      this.sendAuthorStr = this.nameOrg;
    }
  }

  mounted() {
    if (this.orgContactAgent == undefined) {
      this.getOrgAgents(this.orgIdOfContractId, null);
    }
    this.getAuthor();
    this.getUnreadCount();
    this.getChatList();
  }

  created() {
    this.values = {...this.values, file: null};
    this.defaultValues = {...this.values, file: null};
  }

  /**
   * Автоматически расширяем поле ввода сообщения
   */
  rowsCount(): Number {
    return Math.max((this.values['message'] || '').split("\n").length, 2);
  }

  /**
   * если вызвали обновление списка сообщений
   */
  @Emit('updateChatTable')
  public appealsChatUpdate() {
    this.getChatList();
  }

  public log(...data: any) {
    data.forEach((m:any) => console.log(m))
  }

  /**
   * отправка сообщения
   */
  async submitForm() {
    this.useSuccessAlert = false;
    this.useSubmitButtonLoading = true;

    let formData = new FormData();
    for (let key in this.values) {
      if (key == 'file') {
        // если есть файлы, прикрепляем
        if (this.values[key]) {
          for (let i = 0; i < (this.values[key] || []).length; i++) {
            formData.append(key + '[' + i + ']', this.values[key][i]);
          }
        }
      } else {
        formData.append(key, this.values[key]);
      }
    }
    formData.append('contractId', this.contractId);
    formData.append('appealId', this.currentAppealInfo[0]['appealId']);
    formData.append('author', this.sendAuthorStr);

    try {
      const data = await this.sendAppealMessage(formData);
      if (data && data.result) {
        this.submitSuccess = 'Сообщение успешно отправлено';
        this.useSuccessAlert = true;

        this.values = {...this.defaultValues};

        // обновить список сообщений
        this.getChatList();
        this.submitErrorText = "";
      }
    } catch (e) {
      console.log('error', e)
    }

    this.submitSuccess = "";
    this.useSubmitButtonLoading = false;
  }
}

export default AppealChat;
