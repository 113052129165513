












import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import { AppealCustomFields } from "@/models/appeals";

@Component({})
class CheckboxCustomField extends Vue {
  [x: string]: any

  @Prop({ required: true }) readonly item!: AppealCustomFields;
  @Prop({ default: "" }) readonly fieldName!: string;
  @Prop({ default: false }) readonly required!: boolean;
  @Prop({ default: false }) readonly disabled!: boolean;

  value = false;

  mounted() {
    this.item['значение'] = "0";
  }

  @Watch("value")
  valueChanged() {
    this.item['значение'] = this.value ? "1" : "0";
  }
}

export default CheckboxCustomField;
