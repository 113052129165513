function prepareMoney(number: number): string {
  return Number.isNaN(number) || typeof number === 'undefined'
    ? ""
    : number
        .toLocaleString("ru-Ru", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
        .replace(",", ".");
}

function valueOrDash(value: string): string {
  return !value ? "-" : value;
}

export { prepareMoney, valueOrDash, };
