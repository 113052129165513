import {store} from "@/store/store";

// регистрация в лк
function agreementAcceptedExtensions(): string {
  return store.state.appConfig.appConfig["REGISTRATION_AVAILABLE_FORMATS"]
      .split(',')
      .map(_ => '.' + _)
      .join(',');
}
function agreementAcceptedExtensionsHint(): string {
  return 'Доступны форматы: ' + store.state.appConfig.appConfig["REGISTRATION_AVAILABLE_FORMATS"] +
      ', максимальный размер ' + Math.round((store.state.appConfig.appConfig["REGISTRATION_AVAILABLE_BYTES"] / (1024 * 1024))).toString() + 'МБ';
}

// обращения
function appealsAcceptedExtensions(): string {
  return store.state.appConfig.appConfig["APPEALS_AVAILABLE_FORMATS"]
      .split(',')
      .map(_ => '.' + _)
      .join(',');
}
function appealsAcceptedExtensionsHint(): string {
  return 'Доступны форматы: ' + store.state.appConfig.appConfig["APPEALS_AVAILABLE_FORMATS"] +
      ', максимальный размер ' + Math.round((store.state.appConfig.appConfig["APPEALS_AVAILABLE_BYTES"] / (1024 * 1024))).toString() + 'МБ';
}

export {
  agreementAcceptedExtensions, agreementAcceptedExtensionsHint,
  appealsAcceptedExtensions, appealsAcceptedExtensionsHint,
};
