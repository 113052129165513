



















import {Component, Prop, Vue} from "vue-property-decorator";
import {AppealCustomFields} from "@/models/appeals";

@Component({})
class EnumCustomField extends Vue {
  [x: string]: any

  @Prop({ required: true }) readonly item!: AppealCustomFields;
  @Prop({ default: "" }) readonly fieldName!: string;
  @Prop({ default: false }) readonly required!: boolean;
  @Prop({ default: false }) readonly disabled!: boolean;

  setValue(data) {
    this.item['значение'] = data;
  }

  enumRule() {
    if (this.required) {
      return [
        v => (Number.isInteger(v) && v >= 0 && v < this.item['возможныеЗначения'].split(';').length) || "Выберите значение",
      ];
    }
    return [];
  }
}

export default EnumCustomField;
