






















import {Component, Prop, Vue} from "vue-property-decorator";
import { AppealCustomFields } from "@/models/appeals";
import {appealsAcceptedExtensions, appealsAcceptedExtensionsHint} from "@/assets/scripts/file-inputs";

@Component({})
class FileCustomField extends Vue {
  [x: string]: any

  @Prop({ required: true }) readonly item!: AppealCustomFields;
  @Prop({ default: "" }) readonly fieldName!: string;
  @Prop({ default: false }) readonly required!: boolean;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({ default: true }) readonly single!: boolean;

  // TODO научиться работать с маской

  /**
   * Доступные расширения для файлов
   */
  getAppealsAcceptedExtensions() { return appealsAcceptedExtensions() }
  getAppealsAcceptedExtensionsHint() { return appealsAcceptedExtensionsHint() }

  onFileChanged(files: Array<File>) {
    this.item['значение'] = files[0];
  }

  customRuleFile() {
    if (this.required) {
      return [
        v => !(v == undefined || v.length == 0) || "Заполните данное поле"
      ];
    }
    return [];
  }
}

export default FileCustomField;
