

































































































































































































import AppApiMixin from "@/components/mixins/AppApi.vue";
import {Component, Mixins, Watch} from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import XNotice from "@/components/hoc/SimpleNotice.vue";
import CustomForm from "@/components/CustomForm.vue";
import SimpleButton from "@/components/SimpleButton.vue";
import {mapGetters, mapMutations, mapState} from "vuex";
import {AppealCustomFields, AppealTypes, AppealThemes} from "@/models/appeals";
import {AccountingPoint} from "@/models/accounting-point";
import {appealsAcceptedExtensions, appealsAcceptedExtensionsHint} from "@/assets/scripts/file-inputs";
import {FieldTypes} from "@/assets/scripts/custom-fields/field-types";

@Component({
  components: {
    Page,
    XNotice,
    CustomForm,
    SimpleButton,
  },
  computed: {
    ...mapGetters("contract", {
      contractId: "id",
    }),
    ...mapGetters("user", {
      userEmail: "email",
    }),
    ...mapGetters("accountingPoints", {
      accountingPoints: "allPoints",
    }),
    ...mapGetters("appeals", {
      types: "types",
      themes: "themes",
      appealCustomFields: "appealCustomFields",
    }),
    ...mapState({ accountingPoint: "accountingPoint" }),
    ...mapGetters({
      sortedPointsByObject: "accountingPoints/sortedPointsByObject",
      orgContactAgent: "organization/agentList",
    }),
  },
  methods: {
    ...mapMutations("appeals", {
      setCustomFields: "setCustomFields",
    }),
  },
  data: () => ({
    requiredRule: [
      v => !!v || 'Заполните данное поле',
    ],
    emailRule: [
      v => !!v || 'Заполните данное поле',
      v => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Неправильный формат",
      // v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(v) || "Неправильный формат",
    ],
    phoneRule: [
      v => !!v || 'Заполните данное поле',
      v => {
        const endRegExp = "([- ]?\\d{2}){2}$";
        const phonePatterns = [
          new RegExp("^(\\d{4}|\\(\\d{4}\\))[- ]?\\d{2}" + endRegExp),
          new RegExp("^(\\d{3}|\\(\\d{3}\\))[- ]?\\d{3}" + endRegExp)
        ];
        return phonePatterns.some(pattern => pattern.test(v)) || "Неправильный формат";
      }
    ],
  }),
})
class AppealsAdd extends Mixins(AppApiMixin) {
  themeHint: string = "";

  appealTypes: [] = [];
  appealThemes: string[] = [];

  // массив категорий и видов обращений
  appealCategoriesArr: {[category_name: string]: number} = {};
  appealTypesArr: {[category_id: number]: any} = []; // [{[type_name: string]: number}]
  appealThemesArr: {[theme_name: string]: number} = {};

  accountingPointsArr: {[x: string]: number} = {};
  accountingPointsObjectsArr: {[x: string]: number} = {};

  customFields: AppealCustomFields[] = [];

  values: {[x: string]: any} = {};
  isFormValid = false;

  file: Array<File> | [];
  submitSuccessText = "";
  submitErrorText = "";
  useSuccessAlert = false;
  useSubmitButtonLoading = false;
  formDisabled = false;

  noAPointsForSelect = "Точки учета отсутствуют";

  mounted() {
    this.formDisabled = false;
    this.getAllAppealTypes();
    this.getAllAppealThemes();
    this.values['email'] = this.userEmail;
    // Получения данных о заявителе
    if (this.orgContactAgent != undefined) {
      if (Array.from(this.orgContactAgent).length > 0) {
        const agent = Array.from(this.orgContactAgent).shift();
        this.values['fio'] = agent['фио'] || "";
      }
    }
  }

  /**
   * ДАННЫЕ
   */

  /**
   * Список тем обращений
   */
  public get appealCategoriesForSelect() {
    const categories = this.types ? this.types.filter((theme) => theme['$папка'] == -10) : [];
    const categoriesNames = categories.map((name: AppealTypes) => name.название);
    const appealCategories_ids = categories.map((name: AppealTypes) => name.$номерЗаписи);

    categoriesNames.forEach((name, index) => {
      this.appealCategoriesArr[name] = appealCategories_ids[index];
    });

    const types = this.types ? this.types.filter((theme) => theme['$папка'] > 0) : [];
    const typesNames = types.map((name: AppealTypes) => name.название);
    const typesCategories = types.map((name: AppealTypes) => name.$папка);
    const typesIds = types.map((name: AppealTypes) => name.$номерЗаписи);

    typesNames.forEach((name, index) => {
      const category = typesCategories[index];
      if (this.appealTypesArr[category] == undefined) {
        this.appealTypesArr[category] = [];
      }
      this.appealTypesArr[category][name] = typesIds[index];
    });

    return [...categoriesNames];
  }

  /**
   * Список точек учета
   */
  public get accountingPointsForSelect() {
    const accountingPointsNames = this.accountingPoints ? this.accountingPoints.map((name: AccountingPoint) => name.названиету) : [];
    const accountingPointsIds = this.accountingPoints ? this.accountingPoints.map((name: AccountingPoint) => name.лицевой) : [];
    const accountingPointsObjectsIds = this.accountingPoints ? this.accountingPoints.map((name: AccountingPoint) => name.объект) : [];

    accountingPointsNames.forEach((item, index) => {
      this.accountingPointsArr[item] = accountingPointsIds[index];
      this.accountingPointsObjectsArr[item] = accountingPointsObjectsIds[index];
    });

    if (accountingPointsNames.length > 0) {
      return [...accountingPointsNames];
    } else {
      return [this.noAPointsForSelect];
    }
  }

  /**
   * Доступные расширения для файлов
   */
  getAppealsAcceptedExtensions() { return appealsAcceptedExtensions() }
  getAppealsAcceptedExtensionsHint() { return appealsAcceptedExtensionsHint() }

  /**
   * СОБЫТИЯ
   */

  @Watch("appealCustomFields")
  public appealCustomFieldsChanged() {
    // очистка values от customFields
    for (let key in this.values) {
      if (key.startsWith('customFields[]')) {
        delete this.values[key];
      }
    }
    // набираем поля
    this.customFields = this.appealCustomFields;
    this.customFields.forEach((item, i) => {
      this.values['customFields[][' +item['id'] + ']'] = item;
    });
  }

  /**
   * получение тем обращений
   */
  getThemes() {
    this.appealThemes = [];
    if (this.appealCategoriesArr[this.values['appealCategory']]) {
      let appealType = null;
      const category = this.appealCategoriesArr[this.values['appealCategory']];
      // получение записи
      const categoryObj: AppealTypes = this.types.filter((item) => item['$номерЗаписи'] == category)[0] || [];
      if (categoryObj['$этоПапка']) {
        // для папки смотрим второй уровень
        if (this.appealTypesArr[category] != undefined && this.appealTypesArr[category][this.values['appealType']]) {
          appealType = this.appealTypesArr[category][this.values['appealType']];
        }
      } else {
        appealType = category;
      }
      // корректная запись, получаем темы
      if (appealType != null) {
        const themes = this.themes ? this.themes.filter((theme) =>
          theme['видызаявок-видыобращений'] == appealType ||
          (appealType != category && theme['видызаявок-видыобращений'] == category)) : [];

        let themesArr: AppealThemes[] = [];
        themes.forEach((themeParent) => {
          const tmp = this.themes.filter((theme) => theme['$папка'] == themeParent['$номерЗаписи']);
          themesArr = themesArr.concat(tmp);
        });

        this.appealThemes = themesArr.map((name: AppealThemes) => name.название);
        const themesIds = themesArr.map((name: AppealThemes) => name.$номерЗаписи);

        this.appealThemes.forEach((name, index) => {
          this.appealThemesArr[name] = themesIds[index];
        });
      }
    }
  }

  /**
   * Изменение выбранной категории (уровень 1)
   */
  onSelectCategory() {
    this.themeHint = "";
    this.appealTypes = [];
    this.setCustomFields([]);
    if (this.values['appealCategory']) {
      // тема из категории
      const category = this.appealCategoriesArr[this.values['appealCategory']];
      const types = this.types ? this.types.filter((theme) => theme['$папка'] == category) : [];
      this.appealTypes = types.map((name: AppealTypes) => name.название);
      this.values['appealType'] = "";

      if (this.appealTypes.length == 0) {
        // это конечный вид
        this.getCustomFieldsData({
          typeId: this.appealCategoriesArr[category],
          themeId: this.appealThemesArr[this.values['appealTheme']] ?? null,
        });
        // подсказка
        this.themeHint = this.types.filter((theme) => theme['$номерЗаписи'] == category)[0]['подсказкалк'] || "";
      }
    }
    // набираем темы
    this.getThemes();
  }

  /**
   * Изменение выбранного вида обращения (уровень 2)
   */
  onSelectType() {
    this.setCustomFields([]);
    if (this.appealCategoriesArr[this.values['appealCategory']]) {
      const category = this.appealCategoriesArr[this.values['appealCategory']];
      if (this.appealTypesArr[category][this.values['appealType']]) {
        const type = this.appealTypesArr[category][this.values['appealType']];
        this.getCustomFieldsData({
          typeId: type,
          themeId: this.appealThemesArr[this.values['appealTheme']] ?? null,
        });
        // подсказка
        this.themeHint = this.types.filter((theme) => theme['$номерЗаписи'] == type)[0]['подсказкалк'] || "";
      }
    }
    // набираем вопросы
    this.getThemes();
  }

  /**
   * Изменение выбранной темы (уровень 3)
   */
  onSelectTheme() {
    let type = this.appealCategoriesArr[this.values['appealCategory']];
    if (this.appealTypesArr[type] != undefined) {
      type = this.appealTypesArr[type][this.values['appealType']];
    }
    this.getCustomFieldsData({
      typeId: type,
      themeId: this.appealThemesArr[this.values['appealTheme']] ?? null,
    });
  }

  /**
   * Изменение поля файлов
   */
  onFileChanged(files: Array<File>) {
    this.file = files;
  }

  /**
   * Отправка обращения
   */
  async submitForm() {
    this.useSuccessAlert = false;
    this.useSubmitButtonLoading = true;
    const formData = new FormData;

    formData.append('contractId', this.contractId);

    for (let key in this.values) {
      switch (key) {
        case "appealTheme":
          if (this.values[key] != "" && this.appealThemesArr && this.appealThemesArr[this.values[key]]) {
            formData.append(key, this.appealThemesArr[this.values[key]].toString());
          }
          break;
        case "accountingPoint":
          if (this.values[key] != "" && this.accountingPointsArr && this.accountingPointsArr[this.values[key]]) {
            formData.append(key, this.accountingPointsArr[this.values[key]].toString());
          }
          break;
        case "file":
          if (this.file) {
            for (let file of this.file) {
              formData.append("file[]", file);
            }
          }
          break;
        default:
          if (key.indexOf('customFields') < 0 && key != "appealCategory" && key != "appealType") {
            formData.append(key, this.values[key]);
          }
          break;
      }
    }

    let appealType = this.appealCategoriesArr[this.values['appealCategory']];
    if (this.values['appealType']) {
      const type = this.values['appealType'];
      if (this.appealTypesArr[appealType][type]) {
        appealType = this.appealTypesArr[appealType][type];
      }
    }
    formData.append('appealType', appealType.toString());

    // кастомные поля
    for (let key in this.customFields) {
      let item = [];

      // общее для всех
      item['параметр'] = this.customFields[key]['id'];
      formData.append('appealCustom['+ key + '][параметр]', item['параметр']);

      // подготовка полей
      item['значение'] = this.customFields[key]['значение'] || "";
      item['свойвариант'] = this.customFields[key]['свойвариант'] || "";
      if (item['значение'].length == 0 && item['свойвариант'].length == 0) {
        // не заполнено значение и нет своего варианта, ставим значение по умолчанию
        item['значение'] = this.customFields[key]['поУмолчанию'];
      }
      // свой вариант
      if (item['свойвариант']) {
        formData.append('appealCustom['+ key + '][свойвариант]', item['свойвариант']);
      }

      if (this.customFields[key]['тип'] === FieldTypes.MONTH && item['значение']) {
        let date = item['значение'].split('T')[0].split('-');
        formData.append('appealCustom['+ key + '][значение]',date[1] + '.' + date[0]);
      } else if (this.customFields[key]['тип'] === FieldTypes.DATE && item['значение']) {
        let date = item['значение'].split('T')[0].split('-');
        formData.append('appealCustom['+ key + '][значение]',date[2] + '.' + date[1] + '.' + date[0]);
      } else {
        formData.append('appealCustom['+ key + '][значение]', item['значение']);
      }
    }

    try {
      const data = await this.sendAppeal(formData);

      if (data && data.result && (data.appealId) != 0) {
        this.submitSuccessText = "Обращение успешно отправлено";
        this.useSuccessAlert = true;

        // блокируем форму от повторных нажатий
        this.formDisabled = true;

        setTimeout(() => (this.$router.push({ name: "appealsByContract", params: { contractId: this.contractId } })), 2000);
      }

    } catch (e) {
      this.submitErrorText = e.data;
    }
    this.submitSuccessText = "";
    this.useSubmitButtonLoading = false;
  }
}

export default AppealsAdd

