


































import {Component, Mixins} from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import {mapGetters, mapMutations} from "vuex";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import {formatDate} from "@/lib/date";
import DocGetterMixin from "@/components/mixins/DocGetter.vue";
import SimpleButton from "@/components/SimpleButton.vue";

@Component({
  components: { Page, SimpleButton },
  computed: {
    ...mapGetters({contractId: "contract/id"}),
    ...mapGetters({notificationsList: "notifications/notificationsList"}),
    currentNotificationText() {
      return (this.currentNotification && this.currentNotification['@текст']) || "";
    },
    currentNotificationDate() {
      let date = this.currentNotification?.датавремя || "";
      if (date != "") {
        date = "Уведомление от " + formatDate(date, "full");
      }
      return date;
    },
  },
  methods: {
    ...mapMutations("error", {
      setShow: "setShow",
      setMessage: "setMessage"
    }),
  },
  data() {
    return {
      currentNotification: null,
      files: [],
      images: [],
    }
  }
})
class Notifications extends Mixins(AppApiMixin, DocGetterMixin) {
  [x: string]: any;

  loading: boolean = false;

  async mounted() {
    const notificationId = parseInt(this.$route.params.notificationId);
    if (notificationId > 0) {
      this.currentNotification = this.notificationsList.filter(item => item['$номерЗаписи'] == notificationId).shift();
      // проверка файлов
      if (this.currentNotification['@естьвложения'] == 1) {
        await this.getNotificationFiles(this.currentNotification['$номерЗаписи'], ({data: fetchedFiles}) => {
          this.files = fetchedFiles.filter((file) => {
            return !["bmp", "gif", "jpeg", "jpg", "png", "tiff", "svg"].includes((file.расширение || "").toLowerCase());
          });
          const images = fetchedFiles.filter((file) => {
            return ["bmp", "gif", "jpeg", "jpg", "png", "tiff", "svg"].includes((file.расширение || "").toLowerCase());
          });
          images.forEach((image) => {
            this.getFileByNotification({notificationId: this.currentNotification['$номерЗаписи'], fileId: image['$номерЗаписи']})
              .then((response) => {
                const { data } = response;
                this.images.push(URL.createObjectURL(data));
              })
          });
        });
      }
      // пометка прочитанным
      if (this.currentNotification.статус == 2) {
        this.readNotification(notificationId, (data) => {
          if (data.result) {
            this.fetchNotifications();
          }
        });
      }
    }
  }

  // получение файлов
  getFile(fileId) {
    this.loading = true;
    this.getFileByNotification({notificationId: this.currentNotification['$номерЗаписи'], fileId})
      .then((response) => {
        this.downloadDocument(response);
      })
      .catch((_) => {
        this.setMessage("Ошибка скачивания файла");
        this.setShow(true);
      })
      .finally(() => (this.loading = false));
  }
}

export default Notifications;
