











































































import {Component, Prop, Vue} from "vue-property-decorator";
import { AppealCustomFields } from "@/models/appeals";

@Component({})
class ComboboxCustomField extends Vue {
  [x: string]: any

  @Prop({ required: true }) readonly item!: AppealCustomFields;
  @Prop({ default: "" }) readonly fieldName!: string;
  @Prop({ default: false }) readonly required!: boolean;
  @Prop({ default: false }) readonly disabled!: boolean;

  showCustomInput = false;

  // переменные для полей, которые не могут работать напрямую со значением
  multiField = [];

  multiMask(data) {
    // установка флага ответа
    let bitMask = 0;
    this.item['возможныеЗначения'].split(';').forEach((el, i) => {
      if (data.split(';').includes(el)) {
        bitMask += Math.pow(2, i);
      }
    });
    this.item['значение'] = bitMask.toString();
  }

  setValueRadio(val) {
    if (this.item['мультивыбор'] === 2) {
      this.showCustomInput = false;
    }
    this.multiMask(val);
  }

  setValueRadioCustom() {
    this.showCustomInput = true;
    this.item['свойвариант'] = "";
    this.item['значение'] = "0";
  }

  setValueCheckbox() {
    if (this.item['мультивыбор'] === 1 || this.item['мультивыбор'] === 3) {
      if (this.item['мультивыбор'] === 3) {
        this.showCustomInput = this.multiField.includes(undefined);
        if (!this.showCustomInput) {
          this.item['свойвариант'] = "";
        }
      }
      this.multiMask(this.multiField.filter(_ => _ != undefined).join(';'));
    }
  }

  multiRule(key) {
    if (this.required) {
      return [
        v => {
          if (this.item['мультивыбор'] === 1) {
            if (this.multiField.length == 0) {
              if (key == this.item['возможныеЗначения'].split(';').length - 1) {
                // вывод ошибки для последнего поля
                return "Выберите значение";
              } else {
                // не выводим ошибку для остальных полей
                return "";
              }
            }
          }
          if (this.item['мультивыбор'] === 3) {
            if (this.multiField.length == 0) {
              return "";
            }
          }
          return true;
        }
      ]
    }
  }

  customRule() {
    if (this.required) {
      return [
        v => !!v || "Заполните данное поле",
      ];
    }
    return [];
  }
}

export default ComboboxCustomField;
