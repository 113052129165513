

























































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import { AppealCustomFields } from "@/models/appeals";
import DropdownWithDatePickerWithoutRange from "@/components/hoc/DropdownWithDatePickerWithoutRange.vue";
import TimePicker from "@/components/hoc/TimePicker.vue";
import DropdownWithRadioButtons from "@/components/hoc/DropdownWithRadioButtons.vue";
import XDropdownWithRadioButtons from "@/components/hoc/DropdownWithRadioButtons.vue";
import {FieldTypes} from "@/assets/scripts/custom-fields/field-types";
import XRangeCustomField from "@/components/for-custom-form/RangeCustomField.vue";
import XEnumCustomField from "@/components/for-custom-form/EnumCustomField.vue";
import XComboboxCustomField from "@/components/for-custom-form/ComboboxCustomField.vue";
import XFileCustomField from "@/components/for-custom-form/FileCustomField.vue";
import XCheckboxCustomField from "@/components/for-custom-form/CheckboxCustomField.vue";

@Component({
  computed: {
    FieldTypes() {
      return FieldTypes
    },
    fieldName() {
      return this.item['название'] + (this.required ? "*" : "")
    }
  },
  components: {
    XDropdownWithRadioButtons,
    DropdownWithDatePickerWithoutRange,
    TimePicker,
    DropdownWithRadioButtons,
    XRangeCustomField,
    XEnumCustomField,
    XComboboxCustomField,
    XFileCustomField,
    XCheckboxCustomField,
  }
})
class CustomForm extends Vue {
  [x: string]: any

  @Prop({ default: "" }) readonly errorMessages!: string;
  @Prop({ required: true }) readonly item!: AppealCustomFields;
  @Prop({ default: false }) readonly required!: boolean;
  @Prop({ default: false }) readonly disabled!: boolean;

  mounted() {
    // TODO убрать условия когда все виды будут приведены в порядок
    if (![FieldTypes.CHECKBOX,FieldTypes.RANGE,FieldTypes.ENUM,FieldTypes.COMBOBOX,FieldTypes.FILE].includes(this.item['тип'])) {
      this.item['значение'] = "";
    }
  }

  setValue(data) {
    this.item['значение'] = data;
  }

  setDateTime(data) {
    if(data['displayTime'] !== undefined) {
      this.item['значение-2'] = data['displayTime'] + ':00';
    } else {
      this.item['значение-1'] = data.split('T')[0].split('-').reverse().join('.');
    }
    this.item['значение'] = this.item['значение-1'] + ' ' + this.item['значение-2'];
  }

  customRule() {
    if (this.required) {
      return [
        v => !!v || "Заполните данное поле",
      ];
    }
    return [];
  }
}

export default CustomForm;
