import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import AppConfigModule from "@/store/modules/app-config";
import AccountingPointsModule from "@/store/modules/accounting-points";
import AccountingPointModule from "@/store/modules/accounting-point";
import EnvironmentModule from "@/store/modules/environment";
import AuthModule from "@/store/modules/auth";
import ContractsModule from "@/store/modules/contracts";
import ContractModule from "@/store/modules/contract";
import DialogModule from "@/store/modules/dialog";
import ErrorModule from "@/store/modules/error";
import OrganizationModule from "@/store/modules/organization";
import PreloaderModule from "@/store/modules/preloader";
import SidebarModule from "@/store/modules/sidebar";
import UserModule from "@/store/modules/user";
import HourlyConsumption from "@/store/hourly-consumption";
import Asd from "@/store/asd";
import AppealsModule from "@/store/modules/appeals";
import NotificationsModule from "@/store/modules/notifications";

Vue.use(Vuex);

interface RootStore {
  appConfig: AppConfigModule,
  accountingPoints: AccountingPointsModule;
  accountingPoint: AccountingPointModule;
  appEnvironment: EnvironmentModule;
  auth: AuthModule;
  contracts: ContractsModule;
  contract: ContractModule;
  hourlyConsumption: HourlyConsumption;
  asd: Asd
  dialog: DialogModule;
  error: ErrorModule;
  organization: OrganizationModule;
  preloader: PreloaderModule;
  sidebar: SidebarModule;
  user: UserModule;
  appeals: AppealsModule;
  notifications: NotificationsModule;
}

/**
 * Данный блок кода обеспечивает хранение состояния приложения в localStorage,
 * что позволяет приложению использовать его при входе или перезагрузке страницы.
 */
const vuexLocalStorage = new VuexPersistence<RootStore>({
  key: "personalCompanyAccount",
  storage: window.localStorage,
  reducer: state => ({
    appConfig: state.appConfig,
    accountingPoints: state.accountingPoints,
    accountingPoint: state.accountingPoint,
    appEnvironment: state.appEnvironment,
    hourlyConsumption: state.hourlyConsumption,
    asd: state.asd,
    auth: state.auth,
    contracts: state.contracts,
    contract: state.contract,
    error: state.error,
    organization: state.organization,
    user: state.user,
    appeals: state.appeals,
    notifications: state.notifications,
  })
});

const store = new Vuex.Store<RootStore>({
  modules: {
    appConfig: AppConfigModule,
    accountingPoints: AccountingPointsModule,
    accountingPoint: AccountingPointModule,
    appEnvironment: EnvironmentModule,
    auth: AuthModule,
    contracts: ContractsModule,
    contract: ContractModule,
    hourlyConsumption: HourlyConsumption,
    asd: Asd,
    dialog: DialogModule,
    error: ErrorModule,
    organization: OrganizationModule,
    preloader: PreloaderModule,
    sidebar: SidebarModule,
    user: UserModule,
    appeals: AppealsModule,
    notifications: NotificationsModule,
  },
  plugins: [vuexLocalStorage.plugin]
});

export {store};
